// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-article-js": () => import("./../../../src/components/article.js" /* webpackChunkName: "component---src-components-article-js" */),
  "component---src-components-search-categories-js": () => import("./../../../src/components/searchCategories.js" /* webpackChunkName: "component---src-components-search-categories-js" */),
  "component---src-components-search-tags-js": () => import("./../../../src/components/searchTags.js" /* webpackChunkName: "component---src-components-search-tags-js" */),
  "component---src-components-search-top-news-js": () => import("./../../../src/components/searchTopNews.js" /* webpackChunkName: "component---src-components-search-top-news-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adatvedelmi-nyilatkozat-js": () => import("./../../../src/pages/adatvedelmi-nyilatkozat.js" /* webpackChunkName: "component---src-pages-adatvedelmi-nyilatkozat-js" */),
  "component---src-pages-belfold-js": () => import("./../../../src/pages/belfold.js" /* webpackChunkName: "component---src-pages-belfold-js" */),
  "component---src-pages-bulvar-js": () => import("./../../../src/pages/bulvar.js" /* webpackChunkName: "component---src-pages-bulvar-js" */),
  "component---src-pages-eletmod-js": () => import("./../../../src/pages/eletmod.js" /* webpackChunkName: "component---src-pages-eletmod-js" */),
  "component---src-pages-gazdasag-js": () => import("./../../../src/pages/gazdasag.js" /* webpackChunkName: "component---src-pages-gazdasag-js" */),
  "component---src-pages-idojaras-js": () => import("./../../../src/pages/idojaras.js" /* webpackChunkName: "component---src-pages-idojaras-js" */),
  "component---src-pages-impresszum-js": () => import("./../../../src/pages/impresszum.js" /* webpackChunkName: "component---src-pages-impresszum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kulfold-js": () => import("./../../../src/pages/kulfold.js" /* webpackChunkName: "component---src-pages-kulfold-js" */),
  "component---src-pages-kultura-js": () => import("./../../../src/pages/kultura.js" /* webpackChunkName: "component---src-pages-kultura-js" */),
  "component---src-pages-sport-js": () => import("./../../../src/pages/sport.js" /* webpackChunkName: "component---src-pages-sport-js" */),
  "component---src-pages-techtud-js": () => import("./../../../src/pages/techtud.js" /* webpackChunkName: "component---src-pages-techtud-js" */)
}

